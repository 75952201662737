import React, { useEffect } from 'react'
import Seo from '../components/seo'
import Loadable from 'react-loadable'
import Loader from '../components/Loader/Loader'
import { Layout } from '../components'
//import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import GlobalStyle from '../styles/GlobalStyles'
//import useLocoScroll from '../../../hooks/useLocoScroll'
import {
	Cursor,
	Nav,
	Hero,
	ThreeBackground,
	Slider,
	Tech,
	Process,
	LastProjects,
	Portfolio,
	Footer,
	About,
	Slogan,
	Contact,
	Intro,
	Modal,
} from '../components'

const LazyThreeBackground = Loadable({
	loader: () => import('../components/Containers/ThreeBackground/ThreeBackground'),
	loading: () => <Loader />,
})

const LazyHero = Loadable({
	loader: () => import('../components/Containers/Hero/Hero'),
	loading: () => <Loader />,
})

const LazyLastProjects = Loadable({
	loader: () => import('../components/Containers/LastProjects/LastProjects'),
	loading: () => <Loader />,
})

const LazySlogan = Loadable({
	loader: () => import('../components/Containers/Slogan/Slogan'),
	loading: () => <Loader />,
})

const LazyTech = Loadable({
	loader: () => import('../components/Containers/Tech/Tech'),
	loading: () => <Loader />,
})

const LazyPortfolio = Loadable({
	loader: () => import('../components/Containers/Portfolio/Portfolio'),
	loading: () => <Loader />,
})

const LazyContact = Loadable({
	loader: () => import('../components/Containers/Contact/Contact'),
	loading: () => <Loader />,
})

const LazyAbout = Loadable({
	loader: () => import('../components/Containers/About/About'),
	loading: () => <Loader />,
})

const IndexPage = () => {
	//pokaż scroll po upływie animacji w intro
	// useEffect(() => {
	// 	setInterval(() => {
	// 		document.body.style.overflow = 'visible'
	// 	}, settings.introAnimationTime)
	// }, [])

	// useLocoScroll(true)
	// if (typeof window === 'undefined' || !window.document) {
	// 	return null
	// }
	// const scrollRestriction = useScrollRestoration('klincode')
	return (
		<Layout>
			{/* <Loader /> */}
			<Seo lang="pl" />
			<Nav />
			{/* <Intro /> */}
			<LazyThreeBackground />
			<LazyHero />
			<LazyLastProjects />
			<LazySlogan />
			<LazyTech />
			{/* <Process /> */}
			<LazyPortfolio />
			<LazyContact />
			<LazyAbout />
		</Layout>
	)
}

export default IndexPage
